export interface ISignInResponse {
  readonly access_token: string;
  readonly expires_in: string;
  readonly refresh_token: string;
  readonly refresh_token_expires_in: string;
  readonly token_type: string;
  readonly error?: string;
  readonly user: string;
}

export default {
  sign_in: "/auth/sign_in",
  // sign_up: "/auth/sign_up",
  refresh: "/auth/refresh",
  open_id: "/auth/open_id",
  login_by_openid: "/auth/login_by_openid",
  user_data: "/auth/user_data"
};
