import { RouteConfig } from "vue-router";
import { examinationTaskRoute } from "@/router/task";

export const examinationChildRoutes: RouteConfig[] = [
  {
    name: "me_akt",
    path: "/me/examination/akt",
    meta: {
      checkJwt: true,
      checkPermission: false,
      namePermission: "me_akt",
      name: "ЕПЗ"
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/user/examination/AktTestings.vue"
      )
  },
  {
    name: "me_testings",
    path: "/me/examination/testings",
    meta: {
      checkJwt: true,
      checkPermission: false,
      namePermission: "me_testings",
      name: "Тесты"
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/user/examination/Testings.vue"
      )
  },
  {
    name: "me_tasks",
    path: "/me/examination/tasks",
    props: true,
    meta: {
      checkJwt: true,
      checkPermission: false,
      namePermission: "me_tasks",
      name: "Практические/Индивидуальные задания"
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/user/examination/Tasks.vue"
      )
  }
];

/** Отображается в навигационном меню */
export const examinationRoutes: RouteConfig[] = [
  {
    name: "me_examination",
    path: "/me/examination",
    meta: {
      checkJwt: true,
      checkPermission: false,
      namePermission: "me_examination",
      name: "Проверка знаний"
    },
    redirect: { name: "me_akt" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Examination.vue"),
    children: [...examinationChildRoutes, examinationTaskRoute]
  }
];
