import { ETaskCategory } from "@/models/task/task";

export function normalizedTaskStatus(task_status: number) {
  switch (task_status) {
    case 1:
      return "В работе";
    case 2:
      return "На проверке";
    case 3:
      return "Не успешно";
    case 4:
      return "Успешно";
    default:
      return "";
  }
}

export function normalizedTaskCategory(task_category: number) {
  switch (task_category) {
    case 1:
      return "Индивидуальное задание";
    case 2:
      return "Практическое задание";
    case 3:
      return "Внешнее обучение";
    default:
      return "";
  }
}

export function normalizedTaskStatusColor(task_status: number) {
  switch (task_status) {
    case 1:
      return "font-blue";
    case 2:
      return "font-orange";
    case 3:
      return "font-red";
    case 4:
      return "font-green";
    default:
      return "";
  }
}

export function normalizedTaskStatusIcon(task_status: number) {
  switch (task_status) {
    case 1:
      return "/img/icon-process.svg";
    case 2:
      return "/img/icon-clock.svg";
    case 3:
      return "/img/icon-cancel-red.svg";
    case 4:
      return "/img/icon-done-current.svg";
    default:
      return "";
  }
}
