var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"content",attrs:{"id":"app"}},[_c('div',{staticClass:"content-inner"},[(
        _vm.authorized &&
          _vm.$route.meta?.checkJwt &&
          !_vm.currentUser?.is_lock &&
          _vm.currentUser?.permissions.some(pm => pm.read)
      )?_c('Navigation'):_vm._e(),_c('div',{class:{
        'content-right': _vm.authorized && _vm.$route.meta?.checkJwt,
        'd-flex full-width flex-auto': !_vm.authorized || _vm.currentUser?.is_lock,
        'mobile-app': _vm.authorized && !_vm.$route.meta?.checkJwt
      }},[(_vm.authorized)?[_c('ContentHeader'),_vm._l((_vm.banners),function(banner){return _c('div',{key:banner.id,staticClass:"t-6 banner-block flex-none",class:{
            'banner-block--warning': banner.is_warning,
            'banner-block--critical': banner.is_critical,
            'banner-block--info': banner.is_info
          }},[_c('div',{staticClass:"row"},[_c('p',{staticClass:"font-12 font-bold font-white b-4",domProps:{"innerHTML":_vm._s(banner.value)}})])])})]:_vm._e(),_c('router-view')],2)],1),_c('CNotification')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }