import {
  IsBoolean,
  IsDate,
  IsOptional,
  IsString,
  IsUUID
} from "class-validator";

import TestingTestingVersion from "@/models/testing/testing_version";

interface IUserCheckData {
  id: string;
  full_name: string;
  is_check: boolean;
}

export enum ETestingStatus {
  CREATED = 1, // Создан
  APPROVAL = 2, // На согласовании
  REJECTED = 3, // Отклонен
  CONFIRMED = 4 // Утверждён
}

export const testingStatus = [
  {
    label: "Создан",
    value: ETestingStatus.CREATED
  },
  {
    label: "На согласовании",
    value: ETestingStatus.APPROVAL
  },
  {
    label: "Отклонен",
    value: ETestingStatus.REJECTED
  },
  {
    label: "Утверждён",
    value: ETestingStatus.CONFIRMED
  }
];

export default class TestingTesting {
  @IsUUID("4")
  public readonly id!: string;

  @IsBoolean()
  public is_archive: boolean = false;

  @IsUUID("4")
  public author_id!: string;

  @IsUUID("4")
  public author_name!: string;

  @IsUUID("4")
  public folder_id!: string;

  @IsOptional()
  @IsUUID("4")
  public actual_version_id?: string;

  @IsString()
  public name: string = "";

  @IsOptional()
  @IsDate()
  public created_at?: Date;

  @IsOptional()
  @IsDate()
  public updated_at?: Date;

  public versions: TestingTestingVersion[] = [];

  public reconciliations: IUserCheckData[] = [];
  public actual_version: TestingTestingVersion = new TestingTestingVersion();

  public is_show: boolean = false;
}
