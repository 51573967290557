import { RouteConfig } from "vue-router";

export const supervisorDashboardChildRoutes: RouteConfig[] = [
  {
    name: "skill_categories",
    path: "skill_categories",
    props: true,
    meta: {
      checkJwt: true,
      name: "Тип навыков"
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/dashboards/SkillCategoryChart.vue"
      )
  },
  {
    name: "skill_levels",
    path: "skill_levels",
    props: true,
    meta: {
      checkJwt: true,
      name: "Уровень Т0-Т4"
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/dashboards/SkillLevelChart.vue"
      )
  }
];
