export function normalizedApplicationType(applicationType: number) {
  switch (applicationType) {
    case 1:
      return "Групповое";
    case 2:
      return "Персональное";
    default:
      "";
  }
}

export function normalizedApplicationStatus(applicationStatus: number) {
  switch (applicationStatus) {
    case 1:
      return "Отозвано";
    case 2:
      return "На согласовании";
    case 3:
      return "В работе";
    case 4:
      return "Закрыта";
    case 5:
      return "Отклонена";
    case 6:
      return "В работе";
    case 7:
      return "В работе";
    case 8:
      return "Подтверждена";
    case 9:
      return "На согласовании";
    default:
      "";
  }
}

export function normalizedApplicationStatusColor(applicationStatus: number) {
  switch (applicationStatus) {
    case 1:
      return "font-green font-sm-green";
    case 2:
    case 9:
      return "font-orange font-sm-orange";
    case 3:
    case 6:
    case 7:
      return "font-blue font-sm-blue";
    case 4:
      return "font-gray font-sm-gray";
    case 5:
      return "font-red font-sm-red";
    default:
      return "font-gray font-sm-gray";
  }
}
