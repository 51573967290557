import { RouteConfig } from "vue-router";

/** Отображается в навигационном меню */
export const positionRoutes: RouteConfig[] = [
  {
    path: "/positions",
    name: "positions",
    meta: {
      checkJwt: true,
      checkPermission: true,
      namePermission: "positions",
      name: "Должности"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/positions/Positions.vue")
  },
  {
    path: "/position_levels",
    name: "position_levels",
    meta: {
      checkJwt: true,
      checkPermission: true,
      namePermission: "positions",
      name: "Уровни развития"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/positions/LevelTree.vue")
  }
];
