import { RouteConfig } from "vue-router";

const aktChildRoutes: RouteConfig[] = [
  {
    path: "/team",
    name: "akt_team",
    meta: {
      checkJwt: true,
      checkPermission: true,
      namePermission: "team",
      name: "Моя команда"
    },
    redirect: { name: "team" }
  },
  {
    name: "akt_questions",
    path: "questions",
    props: true,
    meta: {
      checkJwt: true,
      checkPermission: true,
      namePermission: "akt_questions",
      name: "Вопросы ЕПЗ"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/akt/Questions.vue")
  },
  {
    name: "akt_templates",
    path: "templates",
    meta: {
      checkJwt: true,
      checkPermission: true,
      namePermission: "akt_templates",
      name: "Шаблоны ЕПЗ"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/akt/Templates.vue")
  },
  {
    name: "akt_assignments",
    path: "assignments",
    meta: {
      checkJwt: true,
      checkPermission: true,
      namePermission: "akt_assignments",
      name: "Настройки ЕПЗ"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/akt/Assignments.vue")
  },
  {
    name: "akt_passed_testing",
    path: "testings",
    meta: {
      checkJwt: true,
      checkPermission: true,
      namePermission: "akt_passed_testing",
      name: "Завершенные ЕПЗ"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/akt/PassedTestings.vue")
  }
];

/** Отображается в навигационном меню */
export const aktRoutes: RouteConfig[] = [
  {
    path: "/akt",
    name: "akt",
    meta: {
      checkJwt: true,
      checkPermission: true,
      namePermission: "akt",
      child_menu: true,
      name: "Настройки ЕПЗ"
    },
    component: () => import(/* webpackChunkName: "about" */ "../views/Akt.vue"),
    children: aktChildRoutes,
    redirect: { name: "akt_questions" }
  }
];

export const aktOtherRoutes: RouteConfig[] = [
  {
    name: "akt_templates_new",
    path: "/akt/templates/new",
    props: true,
    meta: {
      checkJwt: true,
      name: "Новый шаблон ЕПЗ"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/akt/template/New.vue")
  },
  {
    name: "akt_templates_edit",
    path: "/akt/templates/:id",
    props: true,
    meta: {
      checkJwt: true,
      name: "Редактировать шаблон ЕПЗ"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/akt/template/Edit.vue")
  },
  {
    name: "akt_testing",
    path: "/akt/testings/:id",
    props: true,
    meta: {
      checkJwt: true,
      name: "Результаты ЕПЗ"
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/akt/testings/Testing.vue"
      )
  },
  {
    name: "akt_testing_task",
    path: "/akt/testings/:id/source",
    props: true,
    meta: {
      checkJwt: true,
      name: "Печать задания ЕПЗ"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/akt/testings/Task.vue")
  },
  {
    name: "akt_protocol",
    path: "/akt/protocols",
    props: true,
    meta: {
      checkJwt: true,
      name: "Групповой протокол"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/akt/Protocol.vue")
  },
  {
    name: "akt_import",
    path: "/akt/questions/imports",
    meta: {
      checkJwt: true,
      name: "Список импортов вопросов ЕПЗ"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/akt/Imports.vue")
  },
  {
    name: "akt_import_question",
    path: "/akt/questions/imports/:id",
    props: true,
    meta: {
      checkJwt: true,
      name: "Импорт вопросов ЕПЗ"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/akt/imports/Edit.vue")
  }
];
