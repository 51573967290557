import { days, ONE_DAY_IN_MILISECONDS } from "@/helpers/date";

export function truncate(text: string, length?: number) {
  if (!text) {
    return;
  }
  const clamp = "...";
  const maxLen = length || 25;
  return text.length > maxLen ? text.slice(0, maxLen) + clamp : text;
}

export function humanizedDate(date: string) {
  const targetDate = new Date(date);
  const currentDate = new Date();

  const targetDay = new Date(
    targetDate.getFullYear(),
    targetDate.getMonth(),
    targetDate.getDate()
  ).valueOf();
  const currentDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  ).valueOf();

  let result = "";

  const day = targetDate.getDate();
  const month = targetDate.getMonth() + 1;
  const year = targetDate.getFullYear();

  if (targetDay === currentDay) {
    result += "Сегодня";
  } else if (targetDay === currentDay + ONE_DAY_IN_MILISECONDS) {
    result += "Завтра";
  } else if (targetDay <= currentDay + ONE_DAY_IN_MILISECONDS * 7) {
    result += days[targetDate.getDay()];
  } else {
    result += day < 10 ? `0${day}.` : `${day}.`;
    result += month < 10 ? `0${month}.` : `${month}.`;
    result += `${year}`;
  }

  return result;
}

export function normalizedMessage(head_type: string, units_count?: number) {
  let message = "";
  if (head_type === "test") {
    message += `Завершите ${units_count} `;

    if (units_count === 1) {
      message += "тест ";
    } else if (units_count! >= 2 && units_count! <= 4) {
      message += "теста ";
    } else {
      message += "тестов ";
    }
    message += "и получите оценку";
  } else if (head_type === "application") {
    message += `Подтвердите ${units_count} `;

    if (units_count === 1) {
      message += "заявку";
    } else if (units_count! >= 2 && units_count! <= 4) {
      message += "заявки";
    } else {
      message += "заявок";
    }
  } else if (head_type === "akt") {
    message += "Завершите ЕПЗ и получите оценку";
  } else {
    return message;
  }
  return message;
}
