import { RouteConfig } from "vue-router";

/** Отображается в навигационном меню */
export const priorities: RouteConfig[] = [
  {
    name: "priorities",
    path: "/priorities",
    meta: {
      checkJwt: true,
      name: "Приоритеты",
      checkPermission: true,
      namePermission: "priorities"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Priorities.vue")
  }
];
