import {
  IsBoolean,
  IsDate,
  IsEnum,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID
} from "class-validator";

import { ETestingStatus } from "@/models/testing/testing";
import TestingQuestionVersion from "@/models/testing/question_version";
import UserUser from "@/models/user/user";
import TestingReconciliation from "@/models/testing/reconciliation";

export enum ERepeatType {
  DAY = 1,
  WEEK = 2,
  MONTH = 3,
  YEAR = 4
}

export const repeatType = [
  {
    label: "День",
    value: ERepeatType.DAY
  },
  {
    label: "Неделя",
    value: ERepeatType.WEEK
  },
  {
    label: "Месяц",
    value: ERepeatType.MONTH
  },
  {
    label: "Год",
    value: ERepeatType.YEAR
  }
];

export interface IDataSearchTesting {
  readonly testing_id: string;
  testing_name: string;
  readonly folder_name?: string;
  readonly folder_id?: string;
  readonly testing_status?: ETestingStatus;
  is_archive: boolean;
  is_select?: boolean;
}

export default class TestingTestingVersion {
  @IsUUID("4")
  public id!: string;

  @IsNumber()
  public version: number = 0;

  @IsEnum(ETestingStatus)
  public status!: ETestingStatus;

  @IsDate()
  @IsOptional()
  public created_at?: Date;

  @IsDate()
  @IsOptional()
  public updated_at?: Date;

  @IsString()
  public name: string = "";

  @IsNumber()
  public passing_score: number = 80;

  @IsNumber()
  public completion_interval: number = 120;

  @IsOptional()
  @IsUUID("4")
  public actual_id?: string;

  @IsEnum(ETestingStatus)
  public actual_status!: ETestingStatus;

  @IsBoolean()
  public is_unlimited: boolean = true;

  @IsOptional()
  @IsDate()
  public starts_at?: Date;

  @IsOptional()
  @IsDate()
  public ends_at?: Date;

  @IsBoolean()
  public can_repeat: boolean = false;

  @IsOptional()
  @IsEnum(ERepeatType)
  public repeat_type?: ERepeatType = ERepeatType.DAY;

  @IsOptional()
  @IsNumber()
  public repeat_interval: number = 1;

  @IsOptional()
  @IsDate()
  public starts_repeat_at?: Date;

  @IsOptional()
  @IsDate()
  public ends_repeat_at?: Date;

  @IsUUID("4")
  public testing_id!: string;

  @IsOptional()
  @IsString()
  public author_name?: string;

  public questions: TestingQuestionVersion[] = [];

  public reconciliations: UserUser[] = [];
  public current_approvals: TestingReconciliation[] = [];
  public actual_approvals: TestingReconciliation[] = [];

  public is_show: boolean = false;

  public is_confirmed() {
    return this.status === ETestingStatus.CONFIRMED;
  }

  public is_actual_confirmed() {
    return this.actual_status === ETestingStatus.CONFIRMED;
  }

  public is_approval() {
    return this.status === ETestingStatus.APPROVAL;
  }

  public is_approval_or_rejected() {
    return (
      this.status === ETestingStatus.APPROVAL ||
      this.status === ETestingStatus.REJECTED
    );
  }
}
