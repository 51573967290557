import { RouteConfig } from "vue-router";

interface IChild {
  title: string | string[] | undefined;
  badge?: string | undefined;
  id: string | undefined;
  to?: { name: string };
}

export interface IRoute extends IChild {
  children?: IChild[];
}

export interface IRouteMeta {
  name: string;
  namePermission?: string;
  checkPermission: boolean;
  checkJwt: boolean;
  only_admin?: boolean;
  only_developer?: boolean;
  child_menu: boolean;
}

export function accessAllowed(meta: IRouteMeta, current_user: IJWTUser | null) {
  if (!current_user) {
    return false;
  }

  if (!meta.checkPermission) {
    return true;
  }

  if (meta.only_admin) {
    return current_user.is_admin;
  }

  if (meta.only_developer) {
    return current_user.is_developer;
  }

  return (
    current_user.permissions.find(p => p.name == meta.namePermission)?.read ||
    false
  );
}

export function parseNav(
  routes: RouteConfig[],
  current_user: IJWTUser | null,
  ignore_profile = false
) {
  return routes.reduce((acc, curr) => {
    if (ignore_profile && curr.name === "profile") {
      return acc;
    }

    if (!accessAllowed(curr.meta! as IRouteMeta, current_user)) {
      return acc;
    }

    const route: IRoute = {
      title: curr.meta!.name,
      badge: curr.meta!.badge,
      id: curr.name
    };

    if (curr.meta!.child_menu && curr.children?.length) {
      route.children = parseNav(curr.children, current_user);
    } else {
      route.to = { name: curr.name! };
    }

    return acc.concat(route);
  }, [] as IRoute[]);
}
