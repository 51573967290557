import { normalized_count } from ".";

export function rows_count_failure_skills(count: number) {
  return normalized_count(
    count,
    "навык не был загружен.",
    "навыка не были загружены.",
    "навыков не были загружены."
  );
}

export function rows_count_success_skills(count: number) {
  return normalized_count(
    count,
    "навык импортирован успешно.",
    "навыка импортированы успешно.",
    "навыков импортированы успешно."
  );
}
