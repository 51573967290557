import { RouteConfig } from "vue-router";

const teamChildRoutes: RouteConfig[] = [
  {
    name: "team_all",
    path: "",
    meta: {
      checkJwt: true,
      name: "Моя команда"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/team/All.vue")
  },
  {
    name: "teammate",
    path: ":user_id",
    props: true,
    meta: {
      checkJwt: true,
      name: "Участник"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/team/One.vue")
  },
  {
    name: "testing_result",
    path: ":user_id/testing/:id",
    props: true,
    meta: {
      checkJwt: true,
      name: "Результат теста"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/team/testing/Result.vue")
  }
];

/** Отображается в навигационном меню */
export const teamRoutes: RouteConfig[] = [
  {
    path: "/team",
    name: "team",
    meta: {
      checkJwt: true,
      checkPermission: true,
      namePermission: "team",
      name: "Моя команда"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MyTeam.vue"),
    children: teamChildRoutes,
    redirect: { name: "team_all" }
  }
];
