import _Vue from "vue";
import axios, { AxiosRequestConfig, AxiosInstance } from "axios";

import authRoutes, { ISignInResponse } from "./routes/auth";
import { EPermission } from "@/enums/permissions";

import store from "../store/index";

import JwtDecode from "jwt-decode";

export class Api {
  private cookieDomain: string | undefined = process.env.VUE_APP_DOMAIN;
  private config_skip_check_auth: string[] = [
    "/auth/sign_in",
    "/auth/refresh",
    "/auth/open_id",
    "/auth/login_by_openid"
  ];
  private refresh_token_request: Promise<string | null> | null = null;

  public environment = process.env.VUE_APP_ENVIRONMENT;

  public regexUrl = '^(ftp|http|https):\\/\\/[^ "]+$';
  public apiUrl: string | undefined = process.env.VUE_APP_API;
  public staticUploaderUrl: string | undefined =
    process.env.VUE_APP_STATIC_UPLOADER;

  private httpClient: AxiosInstance;
  private importHttpClient: AxiosInstance;

  constructor() {
    this.importHttpClient = axios.create({ baseURL: this.apiUrl });
    this.httpClient = axios.create({ baseURL: this.apiUrl });

    this.httpClient.interceptors.request.use(
      async config => {
        let authorization_header: string | null = null;
        if (!this.config_skip_check_auth.includes(config.url as string)) {
          authorization_header = await this.authorizeCheck();
        }

        return {
          ...config,
          ...{
            headers: {
              common: {
                Authorization: authorization_header,
                "X-Authorization": this.isUserDeveloper().toString()
              }
            }
          }
        };
      },
      error => {
        return Promise.reject(error);
      }
    );

    this.httpClient.interceptors.response.use(
      async response => {
        return response;
      },
      async error => {
        if (
          error.message === "Network Error" &&
          error.config.url !== "/healthz"
        ) {
          if (!window.location.href.includes("service_unavailable")) {
            return this.redirectToServiceUnavailable();
          } else {
            alert("Сервер временно недоступен. Пожалуйста, попробуйте позже.");
            return Promise.reject(error);
          }
        }

        switch (error.response.status) {
          case 302:
            if (error.response.data.ouath_redirection) {
              window.location.href = error.response.data.link;
            }
            break;
          case 401: // jwt отсутствует
          case 419: // refresh_token не валиден
            return this.redirectToAuth();
          case 503:
            return this.redirectToServiceUnavailable();
          default:
            return Promise.reject(error);
        }
      }
    );
  }

  private redirectToTechnicalWork() {
    if (window.location.href.includes("technical_work")) {
      return;
    } else {
      window.location.href = "/technical_work";
    }
  }

  private redirectToAuth() {
    if (window.location.href.includes("auth")) {
      return;
    } else {
      if (this.isUserDeveloper()) {
        window.location.href = "/auth";
      } else {
        this.redirectToOAuth();
      }
    }
  }

  private redirectToServiceUnavailable() {
    window.location.href = "service_unavailable";
  }

  private getCookie = (key: string) => {
    const allCookies = document.cookie.split(";");

    for (const cookie of allCookies) {
      const kvPair = cookie.split("=");

      if (kvPair[0].trim() === key) {
        return decodeURIComponent(kvPair[1]);
      }
    }

    return "";
  };

  private setCookie = (key: string, value: string, expires?: Date) => {
    let cookieString = `${key}=${value};path=/`;

    if (this.cookieDomain && process.env.NODE_ENV !== "development") {
      cookieString += `;domain=${this.cookieDomain}`;
    }

    if (expires) {
      cookieString += `;expires=${expires.toUTCString()}`;
    }

    document.cookie = cookieString;

    return document.cookie;
  };

  private clearCookie = () => {
    this.setCookie("JWT", "");
    this.setCookie("expires_in", "");
    this.setCookie("refresh_token", "");
    this.setCookie("refresh_token_expires_in", "");
    this.setCookie("is_user_developer", "");
  };

  public unauthorize() {
    this.redirectToAuth();
    this.clearCookie();
  }

  public authorize = async (login: string, password: string) => {
    const { data: res }: { data: ISignInResponse } = await this.post(
      authRoutes.sign_in,
      {
        login,
        password,
        is_dev: true
      }
    );

    if (res.error) {
      throw new Error(res.error);
    }

    this.setCookie("JWT", res.access_token, new Date(res.expires_in));
    this.setCookie("expires_in", res.expires_in, new Date(res.expires_in));
    this.setCookie(
      "refresh_token",
      res.refresh_token,
      new Date(res.refresh_token_expires_in)
    );
    this.setCookie(
      "refresh_token_expires_in",
      res.refresh_token_expires_in,
      new Date(res.refresh_token_expires_in)
    );
    this.setCookie(
      "is_user_developer",
      "true",
      new Date(res.refresh_token_expires_in)
    );

    store.dispatch("updateCurrentUser", {
      authorized: true,
      currentUser: res.user
    });

    return true;
  };

  public authorizeByOpenID = async (token: string) => {
    const { data: res }: { data: ISignInResponse } = await this.post(
      authRoutes.open_id,
      {
        token
      }
    );

    if (res.error) {
      throw new Error(res.error);
    }

    this.setCookie("JWT", res.access_token, new Date(res.expires_in));
    this.setCookie("expires_in", res.expires_in, new Date(res.expires_in));
    this.setCookie(
      "refresh_token",
      res.refresh_token,
      new Date(res.refresh_token_expires_in)
    );
    this.setCookie(
      "refresh_token_expires_in",
      res.refresh_token_expires_in,
      new Date(res.refresh_token_expires_in)
    );
    this.setCookie(
      "is_user_developer",
      "false",
      new Date(res.refresh_token_expires_in)
    );

    store.dispatch("updateCurrentUser", {
      authorized: true,
      currentUser: res.user
    });

    return res;
  };

  public authorizeCheck = async (): Promise<string | null> => {
    let access_token: string | null = this.getCookie("JWT");
    const expires_in = this.getCookie("expires_in");

    const refresh_token = this.getCookie("refresh_token");
    const refresh_token_expires_in = this.getCookie("refresh_token_expires_in");

    // если JWT отсутствует или истёк, но refresh есть и действителен - выполнить обновление JWT
    if (
      (!access_token || !expires_in || new Date(expires_in) < new Date()) &&
      refresh_token &&
      refresh_token_expires_in &&
      new Date(refresh_token_expires_in) > new Date()
    ) {
      if (this.refresh_token_request === null) {
        this.refresh_token_request = this.authorizeRefresh();
      }

      try {
        access_token = await this.refresh_token_request;
      } catch (error) {
        console.error(error);

        return null;
      } finally {
        this.refresh_token_request = null;
      }
    }

    return access_token;
  };

  public authorizeRefresh = async (): Promise<string | null> => {
    const refresh_token = this.getCookie("refresh_token");
    const refresh_token_expires_in = this.getCookie("refresh_token_expires_in");

    if (!refresh_token) {
      this.redirectToAuth();
      return null;
    }

    if (
      refresh_token &&
      refresh_token_expires_in &&
      new Date(refresh_token_expires_in) > new Date()
    ) {
      const { data: res }: { data: ISignInResponse } = await this.post(
        authRoutes.refresh,
        {
          refresh_token
        }
      );
      if (res.error) {
        throw new Error(res.error);
      }

      this.setCookie("JWT", res.access_token, new Date(res.expires_in));
      this.setCookie("expires_in", res.expires_in, new Date(res.expires_in));
      this.setCookie(
        "refresh_token",
        res.refresh_token,
        new Date(res.refresh_token_expires_in)
      );
      this.setCookie(
        "refresh_token_expires_in",
        res.refresh_token_expires_in,
        new Date(res.refresh_token_expires_in)
      );
      this.setCookie(
        "is_user_developer",
        (!!(JwtDecode(res.access_token) as IJWT)?.user).toString(),
        new Date(res.refresh_token_expires_in)
      );

      store.dispatch("updateCurrentUser", {
        authorized: true,
        currentUser: res.user
      });

      if (this.authorizedUser().is_lock) {
        this.redirectToTechnicalWork();
      }

      return res.access_token;
    }

    return null;
  };

  public authorizedUser = () => {
    return store.state.currentUser! as IJWTUser;
  };

  public getUserData = async () => {
    return await this.httpClient.get(authRoutes.user_data);
  };

  public canWrite = (permission: EPermission) => {
    return this.authorizedUser()?.permissions?.find(p => p.name == permission)
      ?.write;
  };

  public get = async (url: string, options?: AxiosRequestConfig) => {
    return await this.httpClient.get(url, {
      ...options
    });
  };

  public post = async (
    url: string,
    body: any,
    options?: AxiosRequestConfig
  ) => {
    return await this.httpClient.post(url, body, {
      ...options
    });
  };

  public put = async (url: string, body: any, options?: AxiosRequestConfig) => {
    return await this.httpClient.put(url, body, {
      ...options
    });
  };

  public destroy = async (url: string, options?: AxiosRequestConfig) => {
    return await this.httpClient.delete(url, {
      ...options
    });
  };

  public upload = async (body: any, options?: AxiosRequestConfig) => {
    return await this.httpClient.post(
      `${this.staticUploaderUrl}/attachments`,
      body,
      {
        ...options,
        ...{
          headers: {
            Authorization: this.getCookie("JWT"),
            "X-Authorization": this.isUserDeveloper().toString()
          }
        }
      }
    );
  };

  public import = async (
    url: string,
    body: FormData,
    options?: AxiosRequestConfig
  ) => {
    return await this.importHttpClient.post(url, body, {
      ...options
    });
  };

  public redirectToOAuth() {
    this.get(authRoutes.login_by_openid);
  }

  private isUserDeveloper() {
    return this.getCookie("is_user_developer");
  }
}

export default {
  install(Vue: typeof _Vue, _options?: any) {
    Vue.prototype.$api = new Api();
  }
};
