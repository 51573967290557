import { RouteConfig } from "vue-router";
import { examinationRoutes } from "@/router/examination";

export const headRoutes: RouteConfig[] = [
  {
    name: "me_notifications",
    path: "/me/notifications",
    meta: {
      checkJwt: true,
      badge: 0,
      checkPermission: false,
      namePermission: "me_notifications",
      name: "Уведомления"
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/notifications/Notifications.vue"
      )
  },
  {
    name: "download",
    path: "/download",
    meta: {
      checkJwt: true,
      checkPermission: false,
      namePermission: "me_dashboard",
      name: "Скачать  моб. приложение"
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/download_app/DownloadAppWeb.vue"
      )
  },
  {
    name: "redirected_from_email",
    path: "/me/notifications/:notification_id",
    props: true,
    meta: {
      checkJwt: true,
      badge: 0,
      checkPermission: false,
      namePermission: "me_notifications",
      name: "Уведомления"
    },

    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/notifications/Notifications.vue"
      )
  }
];

export const userRoutes: RouteConfig[] = [
  {
    name: "me_dashboard",
    path: "/me/dashboard",
    meta: {
      checkJwt: true,
      checkPermission: false,
      namePermission: "me_dashboard",
      name: "Главная"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/user/NewDashboard.vue")
  },
  ...examinationRoutes,
  {
    path: "/me/calendar",
    name: "calendar",
    meta: {
      checkJwt: true,
      name: "Календарь"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Calendar.vue")
  },
  {
    name: "me_skills",
    path: "/me/skills",
    props: true,
    meta: {
      checkJwt: true,
      checkPermission: false,
      namePermission: "me_skills",
      name: "Мои навыки"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/user/Skill.vue")
  },
  {
    name: "me_catalog",
    path: "/me/catalog",
    meta: {
      checkJwt: true,
      checkPermission: false,
      namePermission: "me_catalog",
      name: "План обучения"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/user/Catalog.vue")
  },
  {
    name: "me_profile",
    path: "/me/profile",
    meta: {
      checkJwt: true,
      checkPermission: false,
      namePermission: "me_profile",
      name: "Профиль"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/user/Profile.vue")
  }
];

export const userAktRoutes: RouteConfig[] = [
  {
    name: "akt_user_testing",
    path: "/me/akt/:id",
    props: true,
    meta: {
      checkJwt: true,
      name: "Прохождение ЕПЗ"
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/user/akt_testing/Testing.vue"
      )
  }
];

export const userPassTestingRoutes: RouteConfig[] = [
  {
    name: "user_pass_testing",
    path: "/me/pass_testing/:id",
    props: true,
    meta: {
      checkJwt: true,
      name: "Прохождение Тестирования"
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/user/testing/Testing.vue"
      )
  }
];
