export function normalizedAktQuestionType(akt_question_type: number) {
  switch (akt_question_type) {
    case 1:
      return "Открытый вопрос";
    case 2:
      return "Одиночный выбор";
    case 3:
      return "Множественный выбор";
    default:
      "";
  }
}

export function normalizedAktType(akt_type: number) {
  switch (akt_type) {
    case 1:
      return "Предварительная часть";
    case 2:
      return "Основная часть, обязательный";
    case 3:
      return "Основная часть, необязательный";
    default:
      "";
  }
}

export function normalizedAktStatus(akt_status: number) {
  switch (akt_status) {
    case 1:
      return "Создан";
    case 2:
      return "На согласовании";
    case 3:
      return "Утвержден";
    case 4:
      return "Не утвержден";
    case 5:
      return "Согласование приостановлено";
    default:
      return "";
  }
}

export function normalizedAktText(akt_text: number) {
  switch (akt_text) {
    case 1:
      return "Базовый";
    case 2:
      return "Углубленный";
    default:
      return "";
  }
}

export function normalizedApprove(approve: boolean | null) {
  if (approve) {
    return "Согласовано";
  }

  return "Не согласовано";
}

export function normalizedAssignmentUserWorplaceStatus(akt_status: number) {
  switch (akt_status) {
    case 1:
      return "Не начат";
    case 2:
      return "Начат";
    case 3:
      return "Приостановлен";
    case 4:
      return "Пройден";
    default:
      return "";
  }
}

export function normalizedAssignmentUserWorplaceResultStatus(
  akt_status: number
) {
  switch (akt_status) {
    case 1:
      return "Ожидает прохождения";
    case 2:
      return "Успешно";
    case 3:
      return "Не успешно";
    case 4:
      return "Ожидает проверки";
    default:
      return "";
  }
}

export function normalizedAssignmentUserWorkplaceResultStatusColor(
  status: number
) {
  switch (status) {
    case 1:
      return "font-lightgray";
    case 2:
      return "font-green";
    case 3:
      return "font-red";
    case 4:
      return "font-lightgray";
    default:
      return "font-gray";
  }
}
