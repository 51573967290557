import Vue from "vue";
import Vuex from "vuex";
import { EDeviceTypes } from "@/enums/deviceTypes";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    deviceType: EDeviceTypes.desktop,
    showNavMenu: false,
    authorized: false,
    currentUser: null,
    editTreeUser: null,
    editedTreeUser: null,
    factoryProgramId: null,
    openTreeProgram: new Map(),
    editTreeLevel: null,
    editedTreeLevel: null,
    editedTreeSkill: null,
    editedSkillId: null,
    editedSkillIdElement: null,
    editedSkillLevel: null,
    editedSkillPrefix: null,
    editedSkillReload: null,
    search: "",
    rangeDate: [],
    currentSkill: null,
    programEditMessage: "Черновик",
    countNotification: 0,
    addCalendarEvent: null,
    changeCalendarEvent: null,
    delCalendarEvent: null,
    reloadCalendarEvents: false,
    updatedJob: null,

    creatingTestingFolder: null,
    createdTestingFolder: null,
    updatingTestingFolder: null,
    updatedTestingFolder: null,
    deletingTestingFolder: null,
    deletedTestingFolder: null,
    changingTesting: null,
    changedTesting: null,
    movingFolderId: null,
    movedFolder: null,
    selectedTestingIds: new Set(),
    selectedTestingIdsSize: 0,
    movingTestings: null,
    movedTestings: null,
    changingArchiveTestings: null,
    changedArchiveTestings: null,

    programSkillCategoryId: null,
    programStatusId: null,

    changingAktQuestion: null,
    changedAktQuestion: null,
    removedAktQuestion: null,

    creatingGroupTask: null,
    createdGroupTask: null,
    creatingIndividualTask: null,
    createdIndividualTask: null,
    updatingIndividualTask: null,
    updatedIndividualTask: null,
    deletingIndividualTask: null,
    deletedIndividualTask: null,

    changingAdmissionRole: null,
    changedAdmissionRole: null,
    deletingAdmissionRole: null,
    deletedAdmissionRole: null
  },
  mutations: {
    authorized(state, value) {
      state.authorized = value;
    },
    currentUser(state, value) {
      state.currentUser = value;
    },
    editTreeUser(state, value) {
      state.editTreeUser = value;
    },
    editedTreeUser(state, value) {
      state.editedTreeUser = value;
    },
    factoryProgramId(state, value) {
      state.factoryProgramId = value;
    },
    openTreeProgram(state, value) {
      state.openTreeProgram = value;
    },
    editTreeLevel(state, value) {
      state.editTreeLevel = value;
    },
    editedTreeLevel(state, value) {
      state.editedTreeLevel = value;
    },
    editedTreeSkill(state, value) {
      state.editedTreeSkill = value;
    },
    editedSkillId(state, value) {
      state.editedSkillId = value;
    },
    editedSkillIdElement(state, value) {
      state.editedSkillIdElement = value;
    },
    editedSkillLevel(state, value) {
      state.editedSkillLevel = value;
    },
    editedSkillPrefix(state, value) {
      state.editedSkillPrefix = value;
    },
    editedSkillReload(state, value) {
      state.editedSkillReload = value;
    },
    search(state, value) {
      state.search = value;
    },
    deviceType(state, value) {
      state.deviceType = value;
    },
    showNavMenu(state, value) {
      state.showNavMenu = value;
    },
    rangeDate(state, value) {
      state.rangeDate = value;
    },
    currentSkill(state, value) {
      state.currentSkill = value;
    },
    countNotification(state, value) {
      state.countNotification = value;
    },
    programEditMessage(state) {
      state.programEditMessage = "Черновик сохранен";
    },
    programEditMessageRollback(state) {
      state.programEditMessage = "Черновик";
    },
    addCalendarEvent(state, value) {
      state.addCalendarEvent = value;
    },
    changeCalendarEvent(state, value) {
      state.changeCalendarEvent = value;
    },
    delCalendarEvent(state, value) {
      state.delCalendarEvent = value;
    },
    reloadCalendarEvents(state, value) {
      state.reloadCalendarEvents = value;
    },
    importJobUpdated(state, value) {
      state.updatedJob = value;
    },

    creatingTestingFolder(state, value) {
      state.creatingTestingFolder = value;
    },
    createdTestingFolder(state, value) {
      state.createdTestingFolder = value;
    },
    updatingTestingFolder(state, value) {
      state.updatingTestingFolder = value;
    },
    updatedTestingFolder(state, value) {
      state.updatedTestingFolder = value;
    },
    deletingTestingFolder(state, value) {
      state.deletingTestingFolder = value;
    },
    deletedTestingFolder(state, value) {
      state.deletedTestingFolder = value;
    },
    changingTesting(state, value) {
      state.changingTesting = value;
    },
    changedTesting(state, value) {
      state.changedTesting = value;
    },
    movingFolderId(state, value) {
      state.movingFolderId = value;
    },
    movedFolder(state, value) {
      state.movedFolder = value;
    },
    toggleTestingId(state, value) {
      if (!value) {
        return;
      }

      if (state.selectedTestingIds.has(value)) {
        state.selectedTestingIds.delete(value);
      } else {
        state.selectedTestingIds.add(value);
      }

      state.selectedTestingIdsSize = state.selectedTestingIds.size;
    },
    clearSelectedTestingIds(state, _value) {
      state.selectedTestingIds = new Set();
      state.selectedTestingIdsSize = 0;
    },
    movingTestings(state, value) {
      state.movingTestings = value;
    },
    movedTestings(state, value) {
      state.movedTestings = value;
    },
    changingArchiveTestings(state, value) {
      state.changingArchiveTestings = value;
    },
    changedArchiveTestings(state, value) {
      state.changedArchiveTestings = value;
    },
    programSkillCategoryId(state, value) {
      state.programSkillCategoryId = value;
    },
    programStatusId(state, value) {
      state.programStatusId = value;
    },
    changingAktQuestion(state, value) {
      state.changingAktQuestion = value;
    },
    changedAktQuestion(state, value) {
      state.changedAktQuestion = value;
    },
    removedAktQuestion(state, value) {
      state.removedAktQuestion = value;
    },
    creatingGroupTask(state, value) {
      state.creatingGroupTask = value;
    },
    createdGroupTask(state, value) {
      state.createdGroupTask = value;
    },
    creatingIndividualTask(state, value) {
      state.creatingIndividualTask = value;
    },
    createdIndividualTask(state, value) {
      state.createdIndividualTask = value;
    },
    updatingIndividualTask(state, value) {
      state.updatingIndividualTask = value;
    },
    updatedIndividualTask(state, value) {
      state.updatedIndividualTask = value;
    },
    deletingIndividualTask(state, value) {
      state.deletingIndividualTask = value;
    },
    deletedIndividualTask(state, value) {
      state.deletedIndividualTask = value;
    },
    changingAdmissionRole(state, value) {
      state.changingAdmissionRole = value;
    },
    changedAdmissionRole(state, value) {
      state.changedAdmissionRole = value;
    },
    deletingAdmissionRole(state, value) {
      state.deletingAdmissionRole = value;
    },
    deletedAdmissionRole(state, value) {
      state.deletedAdmissionRole = value;
    }
  },
  actions: {
    toggleProgramEditMessage(context) {
      context.commit("programEditMessage");

      setTimeout(() => {
        context.commit("programEditMessageRollback");
      }, 500);
    },
    updateCurrentUser(context, options) {
      context.commit("authorized", options.authorized);
      context.commit("currentUser", options.currentUser);
    },
    editedTreeUser(context, options) {
      context.commit("editTreeUser", null);
      context.commit("editedTreeUser", options);
    },
    editedTreeLevel(context, options) {
      context.commit("editTreeLevel", null);
      context.commit("editedTreeLevel", options);
    }
  },
  getters: {},
  modules: {}
});
