import { RouteConfig } from "vue-router";

const kpiChildRoutes: RouteConfig[] = [
  {
    name: "kpi_all",
    path: "/kpi/all",
    meta: {
      checkJwt: true,
      name: "Цели (ЕОД)",
      checkPermission: true,
      namePermission: "kpi_all"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/kpi/All.vue")
  },
  {
    name: "kpi_catalog",
    path: "/kpi/catalog",
    meta: {
      checkJwt: true,
      name: "Справочник",
      checkPermission: true,
      namePermission: "kpi_catalog"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/kpi/Catalog.vue")
  },
  {
    name: "kpi_settings",
    path: "/kpi/settings",
    meta: {
      checkJwt: true,
      name: "Настройки",
      checkPermission: true,
      namePermission: "kpi_settings"
    },
    redirect: { name: "kpi_setting_params" }
  },
  {
    name: "kpi_assignments",
    path: "/kpi/assignments",
    meta: {
      checkJwt: true,
      name: "Назначение",
      checkPermission: true,
      namePermission: "kpi_assignments"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/kpi/Assignment.vue")
  },
  {
    name: "kpi_archive",
    path: "/kpi/archive",
    meta: {
      checkJwt: true,
      name: "Архив",
      checkPermission: true,
      namePermission: "kpi_archive"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/kpi/Archive.vue")
  }
];

export const kpiSettingRoutes: RouteConfig[] = [
  {
    name: "kpi_setting_params",
    path: "/kpi/settings/params",
    meta: {
      checkJwt: true,
      name: "Параметры",
      checkPermission: true,
      namePermission: "kpi_settings"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/kpi/Setting.vue")
  },
  {
    name: "kpi_setting_users",
    path: "/kpi/settings/users",
    meta: {
      checkJwt: true,
      name: "Настройка прав",
      checkPermission: true,
      namePermission: "kpi_settings"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/kpi/SettingUser.vue")
  }
];

export const kpiReportRoutes: RouteConfig[] = [
  {
    name: "kpi_report",
    path: "/kpi/reports",
    props: true,
    meta: {
      checkJwt: true,
      name: "Распоряжение"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/kpi/Report.vue")
  }
];

export const kpiRoutes: RouteConfig[] = [
  {
    path: "/kpi",
    name: "kpi",
    meta: {
      checkJwt: true,
      checkPermission: true,
      child_menu: true,
      namePermission: "kpi",
      name: "Цели"
    },
    component: () => import(/* webpackChunkName: "about" */ "../views/Kpi.vue"),
    children: kpiChildRoutes,
    redirect: { name: "kpi_all" }
  }
];
