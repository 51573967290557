export function normalizedSkillDocumentTypes(skillDocumentTypes: number) {
  switch (skillDocumentTypes) {
    case 1:
      return "Групповой";
    case 2:
      return "Индивидуальный";
    default:
      "";
  }
}

export function normalizedEducationTypes(educationTypes: number) {
  switch (educationTypes) {
    case 1:
      return "Внешнее";
    case 2:
      return "Внутреннее";
    default:
      "";
  }
}

export function normalizedCategory(category: number) {
  switch (category) {
    case 1:
      return "Методологический";
    case 2:
      return "Функциональный";
    case 3:
      return "Общий";
    case 4:
      return "Лидерский";
    default:
      "";
  }
}
