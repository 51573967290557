export function normalizedKpiSubLevelType(type_id: number) {
  switch (type_id) {
    case 1:
      return "Коллективный";
    case 2:
      return "Личный";
    default:
      "";
  }
}
