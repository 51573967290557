
import { Component, Vue } from "vue-property-decorator";
import { headRoutes, userRoutes } from "@/router/user";
import { parseNav, IRoute } from "@/helpers/navigation_helper";

@Component
export default class ContentHeader extends Vue {
  protected currentUser: IJWTUser | null = this.$store.state.currentUser;

  public download_qr = `/img/qr/qr-code-${
    this.$api.environment === "development" ? "beta" : this.$api.environment
  }.png`;

  public notificationLink?: IRoute | null = null;
  public downloadLink?: IRoute | null = null;
  public dashboardLink?: IRoute | null = null;
  public profileLink?: IRoute | null = null;

  public user_routes?: IRoute[] = [];

  protected countNotification: number | null = this.$store.state
    .countNotification;

  public profile_active = false;

  protected watchers: Function[] = [];

  public window_width = window.innerWidth;

  protected created() {
    const head_routes = parseNav(headRoutes, this.currentUser);
    this.user_routes = parseNav(userRoutes, this.currentUser);

    this.notificationLink = head_routes.find(
      child => child.id === "me_notifications"
    );
    this.downloadLink = head_routes.find(child => child.id === "download");

    this.dashboardLink = this.user_routes.find(
      child => child.id === "me_dashboard"
    );
    this.profileLink = this.user_routes.find(
      child => child.id === "me_profile"
    );

    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.currentUser = currentUser;
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.countNotification,
        countNotification => {
          this.countNotification = countNotification;

          if (this.notificationLink) {
            this.$set(this.notificationLink, "badge", this.countNotification!);
          }
        }
      )
    );
  }

  public get filtered_routes() {
    return this.window_width < 1024
      ? [this.user_routes?.[0]]
      : this.user_routes;
  }

  public handleResize() {
    this.window_width = window.innerWidth;
  }

  protected mounted() {
    window.addEventListener("resize", this.handleResize);
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });

    window.removeEventListener("resize", this.handleResize);
  }

  public toggleProfile() {
    this.profile_active = !this.profile_active;
  }

  protected signOut() {
    this.$api.unauthorize();
  }
}
