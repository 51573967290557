import { IsBoolean, IsDate, IsEnum, IsString, IsUUID } from "class-validator";

export enum EBannerType {
  INFO = 1, // Информация
  WARNING = 2, // Предупреждение
  CRITICAL = 3 // Критическое предупреждение
}

export const bannerTypes = [
  {
    id: EBannerType.INFO,
    label: "Инфо"
  },
  {
    id: EBannerType.WARNING,
    label: "Предупреждение"
  },
  {
    id: EBannerType.CRITICAL,
    label: "Критическое предупреждение"
  }
];

export default class SettingsBanner {
  @IsUUID("4")
  public readonly id!: string;

  @IsEnum(EBannerType)
  public banner_type: EBannerType = EBannerType.INFO;

  @IsDate()
  public expired_at?: Date | null;

  @IsString()
  public value!: string;

  @IsBoolean()
  public is_active: boolean = true;

  public get is_info() {
    return this.banner_type === EBannerType.INFO;
  }

  public get is_warning() {
    return this.banner_type === EBannerType.WARNING;
  }

  public get is_critical() {
    return this.banner_type === EBannerType.CRITICAL;
  }
}
