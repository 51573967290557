
import { Component, Vue } from "vue-property-decorator";
import { parseNav, IRoute } from "@/helpers/navigation_helper";

import { navRoutes } from "@/router";
import { userRoutes } from "@/router/user";

@Component
export default class Navigation extends Vue {
  protected showMenu: boolean = false;

  protected showMenuMobile: boolean = false;

  protected currentUser: IJWTUser | null = this.$store.state.currentUser;
  protected countNotification: number | null = this.$store.state
    .countNotification;

  protected links: IRoute[] = [];
  protected dashboardLink?: IRoute | null = null;

  protected watchers: Function[] = [];

  protected created() {
    const user_routes = parseNav(userRoutes, this.currentUser);

    this.links = parseNav(navRoutes, this.currentUser, true);
    this.dashboardLink = user_routes.find(child => child.id === "me_dashboard");

    this.showMenu =
      localStorage.menu && this.$store.state.deviceType !== 0
        ? localStorage.menu === "true"
        : false;
    this.$store.commit("showNavMenu", this.showMenu);

    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.currentUser = currentUser;

          this.links = parseNav(navRoutes, this.currentUser, true);
        }
      )
    );
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected saveMenuPos() {
    this.showMenu = !this.showMenu;

    localStorage.menu = this.showMenu;
    this.$store.commit("showNavMenu", this.showMenu);
  }

  protected toggleMobileMenu() {
    this.showMenuMobile = !this.showMenuMobile;
  }

  protected signOut() {
    this.$api.unauthorize();
  }
}
