export const weekdays: number[] = [1, 2, 3, 4, 5, 6, 0];

export const months: string[] = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь"
];

export const months_short: string[] = [
  "Янв",
  "Фев",
  "Мар",
  "Апр",
  "Мая",
  "Июн",
  "Июл",
  "Авг",
  "Сен",
  "Окт",
  "Ноя",
  "Дек"
];

export const days_short: string[] = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];

export const days: string[] = [
  "Воскресенье",
  "Понедельник",
  "Вторник",
  "Среда",
  "Четверг",
  "Пятница",
  "Суббота"
];

export const ONE_DAY_IN_MILISECONDS: number = 24 * 60 * 60 * 1000;

export function toDateString(value: Date | string) {
  if (typeof value === "string") {
    value = new Date(value);
  }

  const year = value.getFullYear();
  const month = value.getMonth() + 1;
  const day = value.getDate();

  const result: string[] = [];

  result.push(`${year}`);
  result.push(month < 10 ? `0${month}` : `${month}`);
  result.push(day < 10 ? `0${day}` : `${day}`);

  return result.join("-");
}
