import { RouteConfig } from "vue-router";

const groupRoutes: RouteConfig[] = [
  {
    name: "groups_all",
    path: "",
    meta: {
      checkJwt: true,
      name: "Группы"
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/staffs_and_groups/groups/All.vue"
      )
  },
  {
    name: "groups_one",
    path: ":id",
    props: true,
    meta: {
      checkJwt: true,
      name: "Группа"
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/staffs_and_groups/groups/One.vue"
      )
  }
];

export const sagChildRoutes: RouteConfig[] = [
  {
    name: "staffs",
    path: "staffs",
    meta: {
      checkJwt: true,
      name: "Список сотрудников"
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/staffs_and_groups/staffs/All.vue"
      )
  },
  {
    name: "staffs_tree",
    path: "staffs_tree",
    meta: {
      checkJwt: true,
      name: "По рабочим местам"
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/staffs_and_groups/staffs/Tree.vue"
      )
  },
  {
    name: "groups",
    path: "groups",
    meta: {
      checkJwt: true,
      name: "Группы"
    },
    redirect: { name: "groups_all" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/staffs_and_groups/Groups.vue"
      ),
    children: groupRoutes
  }
];

/** Отображается в навигационном меню */
export const sagRoutes: RouteConfig[] = [
  {
    path: "/staffs_and_groups",
    name: "staffs_and_groups",
    meta: {
      checkJwt: true,
      checkPermission: true,
      namePermission: "staffs_and_groups",
      name: "Сотрудники"
    },
    redirect: { name: "staffs" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/StaffsAndGroups.vue"),
    children: sagChildRoutes
  }
];
