import { RouteConfig } from "vue-router";

const skillChildRoutes: RouteConfig[] = [
  {
    name: "skills_all",
    path: "",
    meta: {
      checkJwt: true,
      name: "Навыки"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/skills/All.vue")
  }
];

/** Отображается в навигационном меню */
export const skillRoutes: RouteConfig[] = [
  {
    path: "/skills",
    name: "skills",
    meta: {
      checkJwt: true,
      checkPermission: true,
      namePermission: "skills",
      name: "Навыки"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Skills.vue"),
    children: skillChildRoutes,
    redirect: { name: "skills_all" }
  }
];
