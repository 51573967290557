import "reflect-metadata";
import { IsDate, IsEnum, IsNumber, IsOptional, IsUUID } from "class-validator";

import TestingTestingVersion from "@/models/testing/testing_version";
import TestingAssignmentQuestion from "@/models/testing/assignment_question";

export enum ETestingAssignmentStatus {
  APPOINTED = 1, // назначен
  ON_THE_RUN = 2, // на выполнении
  PAUSED = 3, // Приостановлено
  ON_APPROVED = 4, // на проверке
  UN_SUCCESS = 5, // Не успешно
  SUCCESS = 6 // Успешно
}

export default class TestingAssignment {
  @IsUUID("4")
  public readonly id!: string;

  @IsOptional()
  @IsUUID("4")
  public readonly user_id?: string;

  @IsOptional()
  @IsUUID("4")
  public readonly testing_id?: string;

  @IsOptional()
  @IsDate()
  public readonly ends_at?: Date;

  @IsOptional()
  @IsDate()
  public readonly real_starts_at?: Date;

  @IsOptional()
  @IsDate()
  public readonly real_ends_at?: Date;

  @IsNumber()
  public learning_time!: number;

  @IsNumber()
  public max_learning_time!: number;

  @IsNumber()
  public current_question: number = 1;

  @IsNumber()
  public completion_percent: number = 0;

  @IsEnum(ETestingAssignmentStatus)
  public readonly status!: ETestingAssignmentStatus;

  public readonly updated_at?: Date;

  public assignment_questions: TestingAssignmentQuestion[] = [];

  public testing_version: TestingTestingVersion = new TestingTestingVersion();

  public get show_test() {
    return (
      this.status === ETestingAssignmentStatus.APPOINTED ||
      this.status === ETestingAssignmentStatus.ON_THE_RUN ||
      this.status === ETestingAssignmentStatus.PAUSED
    );
  }

  public get show_result() {
    return (
      this.status === ETestingAssignmentStatus.ON_APPROVED ||
      this.status === ETestingAssignmentStatus.SUCCESS ||
      this.status === ETestingAssignmentStatus.UN_SUCCESS
    );
  }
}
