import { RouteConfig } from "vue-router";

export const checkChildRoutes: RouteConfig[] = [
  {
    name: "all",
    path: "",
    meta: {
      checkJwt: true,
      name: "Все"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/testing_check/All.vue")
  },
  {
    name: "question",
    path: ":question_id",
    props: true,
    meta: {
      checkJwt: true,
      name: "Вопросы"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/testing_check/One.vue")
  }
];

/** Отображается в навигационном меню */
export const checkRoutes: RouteConfig[] = [
  {
    path: "/testing_checks",
    name: "testing_checks",
    meta: {
      checkJwt: true,
      checkPermission: true,
      namePermission: "testing_checks",
      name: "Проверка открытых вопросов"
    },
    redirect: { name: "all" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TestingCheck.vue"),
    children: checkChildRoutes
  }
];
