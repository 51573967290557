import { ONE_DAY_IN_MILISECONDS } from "@/helpers/date";
import { EUnitableType } from "@/models/unit/unit_version";

export function toDate(value: string | Date) {
  if (!value) {
    return value;
  }

  const date = new Date(value);
  if (isNaN(date.getTime())) {
    return value;
  }

  let result = "";

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  result += day < 10 ? `0${day}.` : `${day}.`;
  result += month < 10 ? `0${month}.` : `${month}.`;
  result += `${year}`;

  return result;
}

export function toDateTime(value: string) {
  const date = new Date(value);
  let result = "";

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  result += day < 10 ? `0${day}.` : `${day}.`;
  result += month < 10 ? `0${month}.` : `${month}.`;
  result += `${year}`;
  result += " ";
  result += hours < 10 ? `0${hours}:` : `${hours}:`;
  result += minutes < 10 ? `0${minutes}` : `${minutes}`;

  return result;
}

export function toBlogDateTime(value: string) {
  const targetDate = new Date(value);
  const currentDate = new Date();

  const targetDay = new Date(
    targetDate.getFullYear(),
    targetDate.getMonth(),
    targetDate.getDate()
  ).valueOf();
  const currentDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  ).valueOf();

  let result = "";

  const day = targetDate.getDate();
  const month = targetDate.getMonth() + 1;
  const year = targetDate.getFullYear();
  const hours = targetDate.getHours();
  const minutes = targetDate.getMinutes();

  if (targetDay === currentDay) {
    result += "Сегодня в ";
  } else if (targetDay === currentDay - ONE_DAY_IN_MILISECONDS) {
    result += "Вчера в ";
  } else if (targetDay === currentDay + ONE_DAY_IN_MILISECONDS) {
    result += "Завтра в ";
  } else {
    result += day < 10 ? `0${day}.` : `${day}.`;
    result += month < 10 ? `0${month}.` : `${month}.`;
    result += `${year}`;
    result += ` в `;
  }

  result += hours < 10 ? `0${hours}:` : `${hours}:`;
  result += minutes < 10 ? `0${minutes}` : `${minutes}`;

  return result;
}

export function formatName(fio: string) {
  return fio
    ? fio
        .split(/\s+/)
        .map((w, i) => (i ? w.substring(0, 1).toUpperCase() + "." : w))
        .join(" ")
    : "";
}

export function normalizeType(unitable_type: EUnitableType) {
  switch (unitable_type) {
    case "Document::Version": // документ
      return "Документ-ссылка на Digital Library";
    case "Test::Version": // тест
      return "Тест";
    case "Event::Version": // встреча
      return "Встреча";
    case "Practical::Version": // практическое задание
      return "Практическое задание";
    default:
      return "";
  }
}

export function getFileName(url: string) {
  return url?.split("/")?.pop();
}

export function normalized_count(
  count: number,
  value1: string,
  value2: string,
  value3: string
) {
  if (!count) {
    return `0 ${value3}`;
  }

  const cases = [2, 0, 1, 1, 1, 2];

  if (count % 1 > 0) {
    return `0 ${value2}`;
  } else {
    return `${count} ${
      [value1, value2, value3][
        count % 100 > 4 && count % 100 < 20
          ? 2
          : cases[count % 10 < 5 ? count % 10 : 5]
      ]
    }`;
  }
}

export function normalizedSkillsCount(count: number) {
  return normalized_count(count, "навык", "навыка", "навыков");
}

export function normalizedStepsCount(count: number) {
  return normalized_count(count, "шаг", "шага", "шагов");
}

export function normalizedUserCount(count: number) {
  return normalized_count(count, "сотрудник", "сотрудника", "сотрудников");
}

export function normalizedPositionsCount(count: number) {
  return normalized_count(count, "должность", "должности", "должностей");
}

export function normalizedMinutesCount(count: number) {
  return normalized_count(count, "минута", "минуты", "минут");
}

export function normalizedPauseCount(count: number) {
  return normalized_count(
    count,
    "приостановка",
    "приостановки",
    "приостановок"
  );
}

export function normalizedQuestionResultCount(count: number) {
  return normalized_count(count, "ответ", "ответа", "ответов");
}

export function normalizedFileName(file: string) {
  return file.split("/").at(-1);
}

export function normalizedSeconds(totalSeconds: number) {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
  const seconds = totalSeconds % 60;

  let str = "";

  if (hours > 0) {
    str += `${hours} чac `;
  }

  if (minutes > 0) {
    str += `${minutes} мин `;
  }

  if (seconds > 0) {
    str += `${seconds} cек`;
  }

  return str;
}

export function normalizedTimer(totalSeconds: number) {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  let str = "";

  str += minutes < 10 ? `0${minutes}` : `${minutes}`;
  str += ":";
  str += seconds < 10 ? `0${seconds}` : `${seconds}`;

  return str;
}

export function getAcronim(str: string) {
  return str !== ""
    ? str
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), "")
        .toUpperCase()
    : "";
}

export function normalizedAcceptFiles(str: string) {
  if (str.length > 60) {
    return str.substring(0, 60) + "...";
  } else {
    return str;
  }
}

function monthFrom(index: number, inc: boolean = false) {
  if (index > 11 + +inc) {
    return "";
  }

  const months = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь"
  ];

  if (inc) {
    months.unshift("");
  }

  return months[index];
}

export function monthFromIndex(index: number) {
  return monthFrom(index);
}

export function monthFromNumber(index: number) {
  return monthFrom(index, true);
}
