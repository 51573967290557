import { RouteConfig } from "vue-router";

const admin_child_routes: RouteConfig[] = [
  {
    path: "/roles",
    name: "admission_roles",
    meta: {
      name: "Роли",
      checkPermission: true,
      checkJwt: true,
      only_admin: true
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/admission/Roles.vue")
  }
];

/** Отображается в навигационном меню */
export const admin_routes: RouteConfig[] = [
  {
    path: "/admin",
    name: "admin",
    meta: {
      checkJwt: true,
      checkPermission: true,
      only_admin: true,
      child_menu: true,
      name: "Настройки администратора"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Settings.vue"),
    children: admin_child_routes,
    redirect: { name: "admission_roles" }
  }
];
