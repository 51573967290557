import Vue from "vue";
import Vuetify from "vuetify";
import ru from "vuetify/src/locale/ru";

import "../assets/vuetify.min.css";

Vue.use(Vuetify);

const opts = {
  lang: {
    locales: { ru },
    current: "ru"
  }
};

export default new Vuetify(opts);
