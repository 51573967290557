import { RouteConfig } from "vue-router";

const subsRoutes: RouteConfig[] = [
  {
    name: "subscription_program",
    path: "programs/:id",
    props: true,
    meta: {
      checkJwt: true,
      name: "Подписка на программу"
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/subscriptions/Program.vue"
      )
  },
  {
    name: "subscription_unit",
    path: "programs/:id/units/:unit_id",
    props: true,
    meta: {
      checkJwt: true,
      name: "Подписка на юнит"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/subscriptions/Unit.vue")
  },
  {
    name: "trainer_calendar",
    path: "programs/:id/units/:unit_id/calendar",
    props: true,
    meta: {
      checkJwt: true,
      name: "Календарь тренера"
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/subscriptions/Calendar.vue"
      )
  }
];

export const subscriptionRoutes: RouteConfig[] = [
  {
    path: "/subscriptions",
    name: "subscriptions",
    meta: {
      checkJwt: true,
      name: "Подписки"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Subscriptions.vue"),
    children: subsRoutes
  }
];
