import { RouteConfig } from "vue-router";

export const examinationTaskRoute = {
  name: "examination_task",
  path: "/me/examination/tasks/:task_id",
  props: true,
  meta: {
    checkJwt: true,
    checkPermission: false,
    namePermission: "examination_task",
    name: "Практические/Индивидуальные задания"
  },
  component: () =>
    import(/* webpackChunkName: "about" */ "../views/user/examination/Task.vue")
};

const taskChildRoutes: RouteConfig[] = [
  {
    name: "task_all",
    path: "",
    meta: {
      checkJwt: true,
      name: "Проверка результатов"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/tasks/All.vue")
  },
  {
    name: "verify_task",
    path: ":task_id",
    props: true,
    meta: {
      checkJwt: true,
      name: "Задание"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/tasks/Task.vue")
  }
];

/** Отображается в навигационном меню */
export const TasksRoute = {
  name: "responsible_task",
  path: "/tasks",
  props: true,
  meta: {
    checkJwt: true,
    checkPermission: true,
    namePermission: "staffs_and_groups",
    name: "Индивидуальные задания"
  },
  component: () => import(/* webpackChunkName: "about" */ "../views/Tasks.vue"),
  children: taskChildRoutes,
  redirect: { name: "task_all" }
};
