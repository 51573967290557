export const month_text = (month: number): string => {
  if (typeof month === "number") {
    return [
      "января",
      "февраля",
      "марта",
      "апреля",
      "мая",
      "июня",
      "июля",
      "августа",
      "сентября",
      "октября",
      "ноября",
      "декабря"
    ][month];
  } else {
    return "";
  }
};
