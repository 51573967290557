import { RouteConfig } from "vue-router";

const constructorChildRoutes: RouteConfig[] = [
  {
    name: "constructor_programs_index",
    path: "",
    meta: {
      checkJwt: true,
      name: "Программы"
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/constructor/programs/Index.vue"
      )
  },
  {
    name: "constructor_programs_new",
    path: "factories/:id/new",
    props: true,
    meta: {
      checkJwt: true,
      name: "Программа"
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/constructor/programs/New.vue"
      )
  },
  {
    name: "constructor_programs_edit",
    path: "programs/:id/edit",
    props: true,
    meta: {
      checkJwt: true,
      name: "Программа"
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/constructor/programs/Edit.vue"
      )
  }
];

/** Отображается в навигационном меню */
export const constructorRoutes: RouteConfig[] = [
  {
    path: "/constructor",
    name: "constructor",
    meta: {
      checkJwt: true,
      checkPermission: true,
      namePermission: "constructor",
      name: "Программы обучения"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Constructor.vue"),
    children: constructorChildRoutes,
    redirect: { name: "constructor_programs_index" }
  }
];
