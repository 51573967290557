
import { Component, Vue } from "vue-property-decorator";
import { plainToClass } from "class-transformer";

import UAParser from "ua-parser-js";

import ContentHeader from "@/components/Header.vue";
import Navigation from "@/components/Navigation.vue";
import CNotification from "@/components/Notification.vue";

import settingsRoutes from "@/api/routes/settings";

import { EDeviceTypes } from "@/enums/deviceTypes";
import SettingsBanner from "@/models/settings/banner";

@Component({
  components: {
    ContentHeader,
    Navigation,
    CNotification
  }
})
export default class App extends Vue {
  private authorized = this.$store.state.authorized;
  protected currentUser: IJWTUser | null = this.$store.state.currentUser;

  protected watchers: Function[] = [];

  protected banners: SettingsBanner[] = [];

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.authorized,
        authorized => {
          this.authorized = authorized;
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.currentUser = currentUser;
        }
      )
    );

    this.$store.commit("deviceType", this.howDeviceType());

    await this.loadBanners();
  }

  protected async loadBanners() {
    await this.$api
      .get(settingsRoutes.available_banners)
      .then(({ data: res }: { data: SettingsBanner[] }) => {
        this.banners = plainToClass(SettingsBanner, res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  private howDeviceType() {
    const parser = new UAParser();

    switch (parser.getDevice().type) {
      case "mobile":
        return EDeviceTypes.phone;
      case "tablet":
        return EDeviceTypes.tablet;
      default:
        return EDeviceTypes.desktop;
    }
  }
}
