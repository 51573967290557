import { ETestingAssignmentStatus } from "@/models/testing/assignment";

export function normalizedTestingStatus(testing_status: number) {
  switch (testing_status) {
    case 1:
      return "Создан";
    case 2:
      return "На согласовании";
    case 3:
      return "Отклонен";
    case 4:
      return "Утверждён";
    default:
      return "";
  }
}

export function normalizedTestingAssignmentStatus(
  testing_assignment_status: number
) {
  switch (testing_assignment_status) {
    case 1:
      return "Назначен";
    case 2:
      return "На выполнении";
    case 3:
      return "Приостановлено";
    case 4:
      return "На проверке";
    case 5:
      return "Не успешно";
    case 6:
      return "Пройден";
    default:
      return "";
  }
}

export function normalizedTestingAssignmentStatusColor(status_id: number) {
  return status_id === ETestingAssignmentStatus.APPOINTED
    ? "font-blue font-sm-blue"
    : status_id === ETestingAssignmentStatus.ON_THE_RUN
    ? "font-orange font-sm-orange"
    : status_id === ETestingAssignmentStatus.PAUSED
    ? "font-gray font-sm-gray"
    : status_id === ETestingAssignmentStatus.ON_APPROVED
    ? "font-gray font-sm-gray"
    : status_id === ETestingAssignmentStatus.UN_SUCCESS
    ? "font-red font-sm-red"
    : status_id === ETestingAssignmentStatus.SUCCESS
    ? "font-green font-sm-green"
    : "font-gray font-sm-gray";
}
